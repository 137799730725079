import { withRouter } from "react-router-dom";

import { userSelectors } from "../../../globalStore/slices/user/userSlice";
import { useSelector } from "react-redux";

import Drawer from "./Drawer/FixedDrawer";

function SideNav(props) {
  const userRoles = useSelector(userSelectors.userActionsSelector);

  const linksMap = {};
  // This is where we determine what goes into the side nav. This is super clunky and NOT scalable.
  // Been trying to push for time to be smart about this, but until then we are forced to hard code this in.
  Object.keys(userRoles).forEach((action) => {
    switch (action) {
      case "View Asset History":
        linksMap.assetHistory = true;
        linksMap.assets = true;
        linksMap.assetStatus = true;
        break;
      case "View Batches":
        linksMap.batches = true;
        break;
      case "View Devices":
        linksMap.devices = true;
        break;
      case "View Facilities":
        linksMap.facilities = true;
        linksMap.locations = true;
        break;
      case "View Inventory":
        linksMap.inventory = true
        linksMap.inventoryStatus = true;
        linksMap.inventoryHistory = true
        break;
      case "View Organizations":
        linksMap.organizations = true;
        break;
      case "View Products":
        linksMap.products = true;
        break;
      case "View Settings":
        linksMap.settings = true;
        break;
      case "View Users":
        linksMap.users = true;
        break;
      default:
        linksMap.home = true;
        linksMap.support = true;
        linksMap.information = true;
        linksMap.automatedProcesses = true;
        break;
    }
  });

  const navLinks = [
    {
      name: "assets",
      label: "Assets",
      icon: "fa-map-marked-alt",
    },
    { name: "products", label: "Products", icon: "fa-cube" },
    { name: "batches", label: "Batches", icon: "fa-cubes" },
    { name: "facilities", label: "Facilities", icon: "fa-location-arrow" },
    { name: "devices", label: "Devices", icon: "fa-microchip" },
    { name: "inventory", label: "Inventory", icon: "fa-box" },
    { name: "organizations", label: "Organizations", icon: "fa-sitemap" },
    { name: "users", label: "Users", icon: "fa-user" },
    // { name: "automatedProcesses", label: "Automated Processes", icon: "fa-cogs" },
    { name: "settings", label: "Settings", icon: "fa-cog" },
  ].filter((link) => linksMap[link.name]);

  const stickyNavLinks = [
    {
      name: "support",
      label: "Support",
      icon: "fa-question-circle",
      hyperlink: "http://helpdesk.locatorx.com/support/home",
      sticky: true,
    },
    {
      name: "information",
      label: "Information",
      icon: "fa-info-circle",
      children: [
        {
          name: "privacyPolicy",
          hyperlink: "https://wiki.locatorx.com/privacy-policy/",
          label: "Privacy Policy",
        },
        {
          name: "termsOfUse",
          hyperlink: "https://wiki.locatorx.com/terms-of-use/",
          label: "Terms of Use",
        },
      ],
      sticky: true,
    },
  ].filter((link) => linksMap[link.name]);

  return (
    <Drawer navLinks={navLinks} stickyNavLinks={stickyNavLinks} {...props} />
  );
}
export default withRouter(SideNav);
