import Grid from "@material-ui/core/Grid";
import HoverIconButton from "../../ReusedComponents/HoverIconButton";
import { Box, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
// import CustomFooter from "./CustomFooter";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  toolbarDataContainer: {
    display: "flex",
  },
  toolbarData: {
    fontSize: "1.15em",
    marginLeft: "15px",
    marginRight: "15px",
    paddingTop: "5px",
  },
}));

export default function MaterialUiDataGridPro(props) {
  const classes = useStyles();
  const {
    autoHeight = true,
    checkboxSelection = true,
    columns = [],
    customFilters = null,
    customGridToolbar = null,
    disableSelectionOnClick = true,
    exportItemType = "",
    getRowId = () => {},
    handleConfirmationModal = () => {},
    handleExport = null,
    handleRowSelect = () => {},
    loading = false,
    onFilterModelChange = () => {},
    onPageChange = () => {},
    onPageSizeChange = () => {},
    onSelectionModelChange = () => {},
    onSortModelChange = () => {},
    needsCustomToolbar = false,
    pageSize = 100,
    pagination = true,
    rowCount = 0,
    rowHeight = 38,
    rows = [],
    showGridToolBar = false,
    state = {},
    toolBarButtons = () => {},
  } = props;

  const [rowCountState, setRowCountState] = useState(rowCount);
  const [pgSize, setPgSize] = useState(pageSize);
  const [page, setPage] = useState(0);
  const apiRef = useGridApiRef();
  let components = {};

  // Appends the toolbar
  if (showGridToolBar) {
    // If the parent provides a custom toolbar, we will use that. Otherwise we use the default
    components.Toolbar =
      showGridToolBar && customGridToolbar
        ? customGridToolbar
        : showGridToolBar
        ? GridToolbar
        : null;
  }

  // Appends custom filters
  if (customFilters) {
    components.FilterPanel = customFilters;
  }

  // Taken from https://mui.com/x/react-data-grid/pagination/#basic-implementation
  useEffect(() => {
    setRowCountState((prevRowCount) =>
      rowCount !== undefined ? rowCount : prevRowCount
    );
  }, [rowCount]);

  const selectedCount = state.selectedZones
    ? Object.keys(state.selectedZones).length
    : 0;
  const capitalizedExportItemType =
    exportItemType.charAt(0).toUpperCase() + exportItemType.slice(1);

  // I need to figure out what was going on here. Should we use mine or his?
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={classes.container}>
        <Grid className={classes.toolbarDataContainer}>
          <Grid className={classes.toolbarData}>
            <GridToolbarColumnsButton />
          </Grid>
          <Grid className={classes.toolbarData}>
            <GridToolbarFilterButton />
          </Grid>
          <Grid className={classes.toolbarData}>
            <GridToolbarQuickFilter />
          </Grid>
          {/* <Grid className={classes.toolbarData}>{selectedCount} selected</Grid> */}
        </Grid>
        <Grid className={classes.toolbarDataContainer}>
          {toolBarButtons()}
        </Grid>
        {handleExport ?
          <Grid className={classes.toolbarDataContainer}>
            <span
              onClick={() => {
                handleExport(state, handleConfirmationModal);
              }}
            >
              <HoverIconButton
                downloadDocType="Excel"
                exportItemType={exportItemType}
                icon="fas fa-file-export"
                iconDirection="right"
                text="Export"
              />
            </span>
          </Grid>
          :
          <p>{"  "}</p>}

      </GridToolbarContainer>
    );
  }

  return (
    <Box height="80vh">
      <DataGridPro
        apiRef={apiRef}
        autoHeight={autoHeight}
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            quickFilterProps: { debounceMs: 500 },
            showQuickFilter: true,
          },
        }}
        checkboxSelection={checkboxSelection}
        columns={columns}
        components={{
          Toolbar: needsCustomToolbar ? CustomToolbar : GridToolbar,
        }}
        disableSelectionOnClick={disableSelectionOnClick}
        // following 3 props are used to disable toolbar icons when custom toolbar isnt in use
        // disableColumnFilter
        // disableColumnSelector
        disableDensitySelector
        experimentalFeatures={{ newEditingApi: true }}
        filterMode="client"
        getRowId={getRowId}
        keepNonExistentRowsSelected
        loading={loading}
        onFilterModelChange={(newFilterModel) => {
          onFilterModelChange(newFilterModel);
        }}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPgSize(newPageSize)}
        onSelectionModelChange={(selectedRowIds) => {
          handleRowSelect(selectedRowIds);
        }}
        onSortModelChange={onSortModelChange}
        page={page}
        pageSize={pgSize}
        pagination={pagination}
        // paginationMode="client"
        // rowCount={rowCountState}
        // rowHeight={rowHeight}
        rows={rows}
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        sx={{

          // Makes the background of the table white
          backgroundColor: "white",
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },

          // Styles the headers
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#3d5ba9",
            color: "white",
            fontWeight: "bold",
          },

          // Styles the checkbox color
          "& .MuiDataGrid-columnHeader .MuiCheckbox-root": {
            color: "white",
          },

          // Styles the text inside of the header columns
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },

        }}
      />
    </Box>
  );
}
