import { useEffect, useState } from "react";
import { Box, InputLabel, Grid, TextField } from "@mui/material/";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { naturalSort } from "../../../utils/naturalSort";
import { onSubmit } from "./utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CurrencyTextBox from "../../../components/Forms/FieldTypes/CurrencyTextBox";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import MomentUtils from "@date-io/moment";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";
import GenerateCQRContent from "../../../components/Layout/AppBar/GenerateCQRContent";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "1rem",
  },
  label: {
    color: "#6D6E70",
    fontFamily: "Lato",
    fontWeight: "bold",
  },
  inputContainer: {
    margin: ".5rem 0",
    padding: "0 .5rem",
  },
  inputField: {
    width: "100%",
  },
}));

export default function AssetContent(props) {
  const {
    customs,
    facilities,
    handleAlertModal,
    history,
    organization,
    setTab,
  } = props;

  const { categoriesList } = organization;
  const [generateCQR, setGenerateCQR] = useState(false);
  const [newAsset, setNewAsset] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [
    {
      assetTag,
      category,
      description,
      note,
      selectedFacility,
      status,
      timeCreatedUser,
      timeNeeded,
    },
    setState,
  ] = useState({
    assetTag: { error: false, id: "assetTag", value: "" },
    category: { error: false, id: "category", value: "" },
    description: { error: false, id: "description", value: "" },
    note: { id: "note", value: "" },
    selectedFacility: { error: false, id: "selectedFacility", value: "" },
    status: { error: false, id: "status", value: "" },
    timeCreatedUser: { id: "timeCreatedUser", value: "" },
    timeNeeded: { id: "timeNeeded", value: "" },
  });

  const classifications = {};
  for (let currentClassification in props.classifications) {
    const classificationObj = props.classifications[currentClassification];
    if (
      classificationObj.assetMode === "Asset" ||
      classificationObj.assetMode === undefined
    ) {
      classifications[currentClassification] = classificationObj;
    }
  }

  const [availableEvents, setAvailableEvents] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [assetClassifications, setAssetClassifications] = useState({
    ...classifications,
  });
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [customFields, setCustomFields] = useState({});
  const classes = useStyles();

  useEffect(() => {
    const { eventTypesMap = {}, categoriesList = [] } = organization;

    const assetCustoms = customs.filter(
      (custom) => custom.assetMode === "Asset" || custom.assetMode === undefined
    );

    const availableEventItems = [];
    const categoryOptionItems = [];
    const facilityOptionItems = [];
    const fields = {};

    assetCustoms.forEach((asset) => {
      fields[asset.name] = {
        ...asset,
        required: false,
        error: false,
        value: "",
      };
    });

    // Set Facility Options
    Object.keys(facilities)
      .sort((a, b) => {
        return facilities[a].name?.localeCompare(facilities[b].name);
      })
      .forEach((element) => {
        const { name, facilityId } = facilities[element];
        facilityOptionItems.push({ label: name, value: facilityId });
      });

    // Set Category Options
    [...categoriesList]
      .filter(
        // assetMode undefined means the assetMode is Asset
        (item) => item.assetMode === "Asset" || item.assetMode === undefined
      )
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      })
      .forEach((element) => {
        categoryOptionItems.push({ id: element.id });
      });

    // Set Availble Events
    Object.keys(eventTypesMap)
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .forEach((element) => {
        if (element === "Tag Destroyed") {
          return null;
        } else {
          availableEventItems.push({ value: element });
        }
      });

    if (Object.keys(fields).length > 0) {
      setCustomFields(fields);
    }

    setAvailableEvents(availableEventItems);
    setCategoryOptions(categoryOptionItems);
    setFacilityOptions(facilityOptionItems);
  }, [customs, facilities, organization, props]);

  function validateFields() {
    let isValidated = true;

    const fieldsToValidate = [
      assetTag,
      category,
      description,
      selectedFacility,
      status,
    ];

    fieldsToValidate.forEach((element) => {
      const { value, id } = element;

      if (value.length === 0) {
        setState((prevState) => {
          return {
            ...prevState,
            [id]: { ...prevState[id], error: true, id, value },
          };
        });
        isValidated = false;
      }
    });

    Object.keys(customFields).forEach((element) => {
      const { value, name, required } = customFields[element];
      if (required && value?.length === 0) {
        isValidated = false;
        setCustomFields((prevState) => {
          return {
            ...prevState,
            [name]: { ...prevState[name], error: true },
          };
        });
      }
    });

    if (isValidated) {
      let selectedAssetClassifications = {};
      // Handles Classifications
      Object.keys(assetClassifications).forEach((item) => {
        if (
          assetClassifications[item].selectedValue !== null &&
          assetClassifications[item].selectedValue !== undefined &&
          assetClassifications[item].selectedValue.length > 0
        ) {
          // Here we find the parent classification. Then do a .find in the formOptions to pull the childs label
          const selectedItemLabel = assetClassifications[
            item
          ]?.formOptions?.find(
            (element) =>
              element.formOptionId === assetClassifications[item].selectedValue
          )?.label;

          selectedAssetClassifications[item] = selectedItemLabel;
        }
      });

      onSubmit(
        props,
        handleAlertModal,
        {
          assetTag,
          category,
          description,
          note,
          selectedAssetClassifications,
          selectedFacility,
          status,
          timeCreatedUser,
          timeNeeded,
        },
        customFields
      ).then((response) => {
        // Defaults the values
        setState({
          assetTag: {
            error: false,
            id: "assetTag",
            value: "",
          },
          category: {
            value: "",
            error: false,
            id: "category",
          },
          description: {
            error: false,
            id: "description",
            value: "",
          },
          note: { id: "note", value: "" },
          selectedFacility: {
            error: false,
            id: "selectedFacility",
            value: "",
          },
          status: {
            error: false,
            id: "status",
            value: "",
          },
          timeCreatedUser: {
            id: "timeCreatedUser",
            value: "",
          },
          timeNeeded: {
            id: "timeNeeded",
            value: "",
          },
        });

        // Delivers the assetId to the parent component and tells it to switch
        // to the next layout
        setNewAsset(response);
        setGenerateCQR(true);
        setModalOpen(true);
      });
    }
  }

  function clearFields() {
    // Clear Basic Fields
    setState({
      assetTag: { error: false, id: "assetTag", value: "" },
      category: { error: false, id: "category", value: "" },
      description: { error: false, id: "description", value: "" },
      note: { id: "note", value: "" },
      selectedFacility: { error: false, id: "selectedFacility", value: "" },
      status: { error: false, id: "status", value: "" },
      timeCreatedUser: { id: "timeCreatedUser", value: "" },
      timeNeeded: { id: "timeNeeded", value: "" },
    });

    // Clear Custom Fields
    setCustomFields((prevState) => {
      const newState = prevState;
      Object.keys(newState).forEach(
        (item) =>
          (newState[item] = {
            ...newState[item],
            error: false,
            required: false,
            value: "",
          })
      );

      // did you know if you returned newState instead of {...newState} this whole clear function breaks? Wild
      return { ...newState };
    });

    // Clear Classifications
    setAssetClassifications((prevState) => {
      const newState = prevState;
      Object.keys(newState).forEach(
        (item) =>
          (newState[item] = {
            ...newState[item],
            error: false,
            required: false,
            value: "",
            selectedValue: "",
          })
      );

      // did you know if you returned newState instead of {...newState} this whole clear function breaks? Wild
      return { ...newState };
    });
  }

  function closeModal() {
    setTimeout(() => {
      setModalOpen(false);
    }, 300);
  }

  return (
    <Grid container>
      <ModalDialog
        content={
          generateCQR ? (
            <GenerateCQRContent
              asset={newAsset}
              closeModal={closeModal}
              history={history}
              setGenerateCQR={setGenerateCQR}
              setTab={setTab}
            />
          ) : (
            <AssetContent
              {...props}
              closeModal={closeModal}
              handleAlertModal={handleAlertModal}
              onSubmit={onSubmit}
              parentProps={props}
              setGenerateCQR={setGenerateCQR}
              setNewAsset={setNewAsset}
            />
          )
        }
        handleClose={closeModal}
        maxWidth="md"
        open={modalOpen}
        title={generateCQR ? "Generate CQR" : "Add Asset"}
      />
      <Grid item xs={12}>
        <Grid container>
          {/* Asset Tag */}
          <Grid className={classes.inputContainer} item xs={4}>
            <SimpleTextField
              error={assetTag.error}
              helperText={assetTag.error ? "Required Field" : ""}
              id="outlined-basic"
              label="Asset Tag"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  assetTag: {
                    ...prevState.assetTag,
                    value,
                    error:
                      prevState.assetTag.error && value.length > 0
                        ? false
                        : prevState.assetTag.error,
                  },
                }));
              }}
              size="medium"
              value={assetTag.value}
              variant="outlined"
            />
          </Grid>

          {/* Description */}
          <Grid className={classes.inputContainer} item xs={4}>
            <SimpleTextField
              error={description.error}
              helperText={description.error ? "Required Field" : ""}
              id="outlined-basic"
              label="Description"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  description: {
                    ...prevState.description,
                    value,
                    error:
                      prevState.description.error && value.length > 0
                        ? false
                        : prevState.description.error,
                  },
                }));
              }}
              size="medium"
              value={description.value}
              variant="outlined"
            />
          </Grid>

          {/* Category */}
          <Grid className={classes.inputContainer} item xs={4}>
            <InputLabel className={classes.label}>Category</InputLabel>
            <Autocomplete
              id="category"
              options={categoryOptions}
              onChange={(event, value) => {
                if (value) {
                  const { id = "" } = value;
                  // Sets the value in state
                  setState((prevState) => ({
                    ...prevState,
                    category: {
                      ...prevState.category,
                      value: id,
                      error:
                        prevState.category.error && id.length > 0
                          ? false
                          : prevState.category.error,
                    },
                  }));

                  // Checks custom fields to see if any fields need to switched to required
                  // The else statement sets everything to default. In the case they switch
                  // to a category that does not have any custom fields set.
                  const isValidCustomCategory = categoriesList.find(
                    (category) => {
                      return category.id === id;
                    }
                  );

                  if (isValidCustomCategory) {
                    const { requiredFields = [] } = isValidCustomCategory;
                    setCustomFields((prevState) => {
                      let newState = { ...prevState };
                      requiredFields.forEach((field) => {
                        const { required, label } = field;
                        newState[label] = {
                          ...newState[label],
                          required,
                          error: false,
                        };
                      });
                      return newState;
                    });
                  }
                }
              }}
              getOptionLabel={(option) => option.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={category.error}
                  helperText={category.error ? "Required Field" : ""}
                  label={null}
                  size="medium"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          {/* Facility */}
          <Grid className={classes.inputContainer} item xs={4}>
            <InputLabel className={classes.label}>Facility</InputLabel>
            <Autocomplete
              id="facility"
              options={facilityOptions}
              onChange={(event, value) => {
                if (value) {
                  const { value: itemValue = "" } = value;
                  setState((prevState) => ({
                    ...prevState,
                    selectedFacility: {
                      ...prevState.selectedFacility,
                      value: itemValue,
                      error:
                        prevState.selectedFacility.error && itemValue.length > 0
                          ? false
                          : prevState.selectedFacility.error,
                    },
                  }));
                }
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={selectedFacility.error}
                  helperText={selectedFacility.error ? "Required Field" : ""}
                  label={null}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          {/* Status */}
          <Grid className={classes.inputContainer} item xs={4}>
            <InputLabel className={classes.label}>Status</InputLabel>
            <Autocomplete
              id="status"
              options={availableEvents}
              onChange={(event, value) => {
                if (value) {
                  const { value: itemValue = "" } = value;
                  setState((prevState) => ({
                    ...prevState,
                    status: {
                      ...prevState.status,
                      value: itemValue,
                      error:
                        prevState.status.error && itemValue.length > 0
                          ? false
                          : prevState.status.error,
                    },
                  }));
                }
              }}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={status.error}
                  helperText={status.error ? "Required Field" : ""}
                  label={null}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          {/* timeCreatedUser */}
          <Grid
            className={classes.inputContainer}
            item
            xs={4}
            key={`timeCreatedUser`}
          >
            <InputLabel className={classes.label}>Create Date</InputLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} variant="outlined">
              <KeyboardDatePicker
                autoOk
                allowKeyboardControl={false}
                className={classes.inputField}
                disableToolbar
                format="MM/DD/yyyy"
                id={`date-picker-inline-timeCreatedUser`}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                label={null}
                onChange={(event) => {
                  const value = event ? event.format("MM/DD/yyyy") : "";

                  setState((prevState) => ({
                    ...prevState,
                    timeCreatedUser: {
                      ...prevState.timeCreatedUser,
                      value,
                    },
                  }));
                }}
                value={
                  timeCreatedUser.value?.length > 0
                    ? timeCreatedUser.value
                    : null
                }
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {/* timeNeeded */}
          <Grid
            className={classes.inputContainer}
            item
            xs={4}
            key={`timeNeeded`}
          >
            <InputLabel className={classes.label}>Need Date</InputLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} variant="outlined">
              <KeyboardDatePicker
                autoOk
                allowKeyboardControl={false}
                className={classes.inputField}
                disableToolbar
                format="MM/DD/yyyy"
                id={`date-picker-inline-timeNeeded`}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                label={null}
                onChange={(event) => {
                  const value = event ? event.format("MM/DD/yyyy") : "";

                  setState((prevState) => ({
                    ...prevState,
                    timeNeeded: {
                      ...prevState.timeNeeded,
                      value,
                    },
                  }));
                }}
                value={timeNeeded.value?.length > 0 ? timeNeeded.value : null}
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {/* Custom Fields */}
          {Object.keys(customFields).map((element, index) => {
            if (customFields[element].customId) {
              let {
                dataType,
                error = false,
                customId,
                name = "",
                value = "",
              } = customFields[element];

              switch (dataType) {
                case "Currency":
                  return (
                    <Grid
                      className={classes.inputContainer}
                      item
                      key={`${customId}-${index}`}
                      xs={4}
                    >
                      <CurrencyTextBox
                        fullWidth={true}
                        error={error}
                        label={name}
                        onChange={(event) => {
                          setCustomFields((prevState) => ({
                            ...prevState,
                            [name]: {
                              ...prevState[name],
                              error: false,
                              value: event.target.value,
                            },
                          }));
                        }}
                        size="large"
                        value={value}
                        variant="outlined"
                      />
                    </Grid>
                  );
                case "Date":
                  return (
                    <Grid
                      className={classes.inputContainer}
                      item
                      xs={4}
                      key={`${customId}-${index}`}
                    >
                      <InputLabel className={classes.label}>{name}</InputLabel>
                      <MuiPickersUtilsProvider
                        utils={MomentUtils}
                        variant="outlined"
                      >
                        <KeyboardDatePicker
                          autoOk
                          allowKeyboardControl={false}
                          className={classes.inputField}
                          disableToolbar
                          format="MM/DD/yyyy"
                          helperText={error ? "Required Field" : ""}
                          id={`date-picker-inline-${customId}`}
                          inputVariant="outlined"
                          error={error}
                          invalidDateMessage={`${
                            error ? "Required Field" : ""
                          }`}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          label={null}
                          onChange={(event) => {
                            const value = event
                              ? event.format("MM/DD/yyyy")
                              : "";
                            setCustomFields((prevState) => ({
                              ...prevState,
                              [name]: {
                                ...prevState[name],
                                error: false,
                                value,
                              },
                            }));
                          }}
                          value={value.length > 0 ? value : null}
                          variant="inline"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  );
                default:
                  return (
                    <Grid
                      className={classes.inputContainer}
                      item
                      key={`${customId}-${index}`}
                      xs={4}
                    >
                      <InputLabel className={classes.label}>{name}</InputLabel>
                      <TextField
                        className={classes.inputField}
                        error={error}
                        helperText={error ? "Required Field" : ""}
                        label={null}
                        onChange={(event) => {
                          const value = event.target ? event.target.value : "";
                          setCustomFields((prevState) => ({
                            ...prevState,
                            [name]: { ...prevState[name], value, error: false },
                          }));
                        }}
                        type={`${dataType === "Numeric" ? "number" : ""}`}
                        value={value}
                        variant="outlined"
                      />
                    </Grid>
                  );
              }
            }
            return null;
          })}

          {/* Classifications */}
          {Object.keys(assetClassifications).map((parent, index) => {
            const { formOptions, name } = assetClassifications[parent];
            const childrenOptions = [];

            // Populates the select options
            Object.keys(formOptions).forEach((child) => {
              childrenOptions.push({
                label: formOptions[child].label,
                parentId: formOptions[child].referenceId,
                value: formOptions[child].formOptionId,
              });
            });

            return (
              <Grid
                className={classes.inputContainer}
                item
                key={`${name}-${index}`}
                xs={4}
              >
                <InputLabel className={classes.label}>{name}</InputLabel>
                <Autocomplete
                  id={name}
                  options={childrenOptions.sort((a, b) =>
                    naturalSort(a.label, b.label)
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      const { value: itemValue = "" } = value;
                      setAssetClassifications((prevState) => ({
                        ...prevState,
                        [name]: {
                          ...prevState[name],
                          selectedValue: itemValue,
                          value,
                        },
                      }));
                    } else {
                      setAssetClassifications((prevState) => ({
                        ...prevState,
                        [name]: {
                          ...prevState[name],
                          selectedValue: value,
                          value,
                        },
                      }));
                    }
                  }}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label={null} variant="outlined" />
                  )}
                  value={
                    assetClassifications[name].value || { label: "", value: "" }
                  }
                />
              </Grid>
            );
          })}

          {/* Asset Details */}
          <Grid className={classes.inputContainer} item xs={12}>
            <SimpleTextField
              multiline
              id="outlined-basic"
              label="Asset Details - optional"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  note: { ...prevState.note, label: value, value: value },
                }));
              }}
              variant="outlined"
              value={note.label}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.inputContainer} textAlign="right" width="100%">
          {/* Clear Button */}
          <MaterialUiButton
            className={classes.button}
            color="cancel"
            label="Clear"
            onClick={() => {
              clearFields();
            }}
            variant="outlined"
          />

          {/* Submit Button */}
          <MaterialUiButton
            className={classes.button}
            color="primary"
            label="submit"
            onClick={() => {
              validateFields();
            }}
            variant="contained"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
