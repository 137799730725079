import _ from "lodash";
import { formatLatLon } from "../../utils/lat_lon_utils";
import { utc_to_local_12_hour_clock } from "../../utils/date_time_utils";
import moment from "moment-timezone";
import XLSX from "xlsx";

function xlSerialToJsDate(xlSerial) {
  return new Date(
    -2209075200000 + (xlSerial - (xlSerial < 61 ? 0 : 1)) * 86400000
  );
}

export const handleExport = (
  state,
  setModal,
  histories,
  itemLevelDataElements,
  assetsArray,
  zones
) => {
  const timeZone = state.filters?.tz?.value || moment.tz.guess();

  const facilities = state.lists.facilityMap;

  // break histories into array of value objects
  const raw = Object.values(histories);

  // function to pull only the fields we want for our CSV file
  const filterHistories = (histories) => {
    const allowed = [
      "timeOfLog",
      "assetId",
      "assetType",
      "assetTag",
      "event",
      "facilityId",
      "zone",
      "city",
      "state",
      "latitude",
      "longitude",
      "propertiesMap",
      "lastName",
      "firstName",
    ];

    // return only allowed fields
    let filtered = histories.map((el) =>
      Object.keys(el)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = el[key];
          return obj;
        }, {})
    );

    // the propertiesMap is a child object that has valuable data. We'll need to traverse the object again to pull these fields
    let tableArray = [];

    for (let i = 0; i < filtered.length; i++) {
      const renderedTime = utc_to_local_12_hour_clock(
        filtered[i].timeOfLog,
        timeZone
      );

      if (filtered[i].propertiesMap) {
        filtered[i].formData =
          filtered[i].propertiesMap.formData &&
          filtered[i].propertiesMap.formData.length
            ? filtered[i].propertiesMap.formData
                .filter((f) => f.fieldKey !== "note")
                .reduce((x, y) => {
                  return {
                    ...x,
                    [_.startCase(y.fieldKey || "NO KEY")]: y.fieldValue || "",
                  };
                }, {})
            : null;

        filtered[i].notes =
          filtered[i].propertiesMap && filtered[i].propertiesMap.note
            ? filtered[i].propertiesMap.note
            : "";
      }

      const asset = assetsArray.filter((ass) => {
        return ass.assetId === filtered[i].assetId;
      })[0];

      let dataElements = [];
      
      if (asset?.propertiesMap) {
        Object.keys(asset.propertiesMap).forEach((key) => {
          if (itemLevelDataElements[key]) {
            dataElements.push({
              key: key,
              value:
                itemLevelDataElements[key].dataType === "Date"
                  ? moment
                      .utc(xlSerialToJsDate(asset.propertiesMap[key]))
                      .format("MM/DD/YYYY")
                  : asset.propertiesMap[key],
              dataType: itemLevelDataElements[key].dataType,
            });
          }
        });
      }

      // constructing a new obj for our CSV that coordinates with the headers defined in the options object... each obj here is a new row for our CSV
      let obj = {
        "Date/Time": renderedTime || "-",
        "Unit of Measure": filtered[i].assetType ? filtered[i].assetType : "-",
        "Item Tag": filtered[i].assetTag ? filtered[i].assetTag : "-",
        Event: filtered[i].event ? filtered[i].event : "-",
        User: `${filtered[i].lastName || "-"}${
          filtered[i].firstName ? `, ${filtered[i].firstName}` : "-"
        }`,
        "Facility Name":
          !filtered[i].latitude || !filtered[i].longitude
            ? "-"
            : filtered[i].facilityId &&
              facilities[filtered[i].facilityId] &&
              facilities[filtered[i].facilityId].name
            ? facilities[filtered[i].facilityId].name
            : "Not Registered",
        Zone: zones[filtered[i].zone?.zoneId]?.name || "-",
        "Bin Location": filtered[i].zone?.binLocation || "-",
        "City/State": `${filtered[i].city ? filtered[i].city : "-"}, ${
          filtered[i].state ? filtered[i].state : "-"
        }`,
        "Latitude/Longitude":
          filtered[i].latitude && filtered[i].longitude
            ? formatLatLon(filtered[i].latitude, filtered[i].longitude)
            : "-",
        Notes: filtered[i].notes ? filtered[i].notes : "-",
        'PCA Flagged':filtered[i].flagged ? "Flagged" : "-",
        ...filtered[i].formData,
        ...dataElements.reduce((x, y) => {
          return {
            ...x,
            [y.key]: y.value,
          };
        }, {}),
      };

      // pushing to table array
      tableArray.push(obj);
    }
    return tableArray;
  };

  const tableArray = filterHistories(raw);

  if (tableArray.length === 0) {
    return setModal({
      modalShow: true,
      text: `No events selected for export`,
      isError: true,
    });
  }
  const currentTime = moment()
    .tz(
      timeZone && timeZone.value
        ? timeZone.value
        : timeZone
        ? timeZone
        : moment.tz.guess()
    )
    .format("YYYY-MM-DD");

  // XLSX
  const fileName = `Export_${currentTime}.csv`;
  const ws = XLSX.utils.json_to_sheet(tableArray);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);
  XLSX.writeFile(wb, fileName);
};
