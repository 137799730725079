import { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Menu,
  MenuItem,
} from "@material-ui/core";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import HoverIconDropdown from "../../../../ReusedComponents/HoverIconDropdown";

const useStyles = makeStyles((theme) => ({
  barChildren: {
    textAlign: "end",
    width: '100%'
  },
  barChildrenContainer: {
    justifyContent: "flex-end",
    padding: ".5rem !important",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  openMenuIcon: {
    paddingLeft: ".5rem",
  },
  paddingTop: {
    padding: ".5rem !important",
  },
}));

export default function DisplayColumnsDropdown(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  // options set on props
  const { options, setOptions, handleSave, limit, fixedColumns } = props;
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const limitReached =
    limit &&
    Object.keys(options).filter((k) => options[k].checked).length >= limit;

  const [limitError, setLimitError] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setLimitError(false);
  };

  return (
    <div className={`${classes.barChildren}`}>
      <span onClick={handleClick}>
        <HoverIconDropdown
          icon="fas fa-chevron-down"
          iconDirection="right"
          text="Display Columns"
        />
      </span>

      <Menu
        anchorEl={anchorEl}
        id="display-columns"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {limit ? (
          <MenuItem
            dense
            divider
            disabled
            key="instructions"
            style={{ opacity: 1 }}
          >
            <FormControl error={limitError}>
              <FormLabel component="legend">
                Select up to{" "}
                {fixedColumns && fixedColumns.length
                  ? `${limit - fixedColumns.length} additional columns.`
                  : `${limit} columns.`}
              </FormLabel>
            </FormControl>
          </MenuItem>
        ) : null}

        {!_.isEmpty(options)
          ? Object.keys(options).map((id, index) => {
              return (
                <MenuItem
                  aria-checked={options[id].checked || false}
                  key={`${id}-${index}`}
                  role="menuitemcheckbox"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <div
                          onClick={() => {
                            if (limitReached && !options[id].checked) {
                              setLimitError(true);
                            } else {
                              setLimitError(false);
                            }
                          }}
                        >
                          <Checkbox
                            
                            checked={options[id].checked || false}
                            color="primary"
                            name={id}
                            disabled={
                              (limitReached && !options[id].checked) ||
                              (fixedColumns && fixedColumns.includes(id))
                            }
                            onChange={(event) => {
                              const name = event.target.name;
                              const checked = options[name].checked || false;

                              setOptions((prevState) => {
                                //update displayColumnOptions in users properties map and return optional name string
                                handleSave(
                                  {
                                    ...prevState,
                                    [name]: {
                                      ...prevState[name],
                                      checked: !checked,
                                    },
                                  },
                                  name
                                );

                                return {
                                  ...prevState,
                                  [name]: {
                                    ...prevState[name],
                                    checked: !checked,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                      }
                      label={options[id].headerName}
                    />
                  </FormGroup>
                </MenuItem>
              );
            })
          : null}
      </Menu>
    </div>
  );
}
