import LX from "../../assets/LX.png";
import { Box, Grid } from "@material-ui/core";

export default function SplashPage() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          alignItems="center"
          display="flex"
          height="100vh"
          justifyContent="center"
          width="100%"
        >
          <img alt="company logo" id="company-logo" src={LX} height={"10%"} />
        </Box>
      </Grid>
    </Grid>
  );
}
