import { useState } from "react";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  avatar: {
    cursor: "pointer",
    color: "#000000de !important",
    backgroundColor: "#FFFFFF !important",
  },
}));

export default function UserBadge() {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const firstInitial = localStorage
    .getItem("firstName")
    ?.charAt(0)
    .toUpperCase();
  const lastInitial = localStorage.getItem("lastName")?.charAt(0).toUpperCase();
  const initials =
    firstInitial && lastInitial ? `${firstInitial}${lastInitial}` : null;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Avatar
        onClick={(e) => handleClick(e)}
        className={classes.avatar}
        sx={{ height: "45px", width: "45px" }}
      >
        {initials}
      </Avatar>
      <Menu
        classes={{ paper: classes.menuPaper }}
        id="simple-menu"
        autoFocus={false}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            history.push("/settings/user");
            handleClose();
          }}
        >
          View Account Information
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            history.push("/logout");
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
