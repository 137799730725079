import { Box, Card, CardContent, Grid, makeStyles } from "@material-ui/core";
import { downloadCQR } from "./api";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: ".5rem",
    marginBottom: ".5rem",
  },
  itemText: {
    fontWeight: "bold",
    marginRight: ".25rem",
  },
  textUpperCase: {
    textTransform: "uppercase",
  },
}));

export default function DeliverTracking(props) {
  const {
    assetType,
    batchAsset,
    batchName,
    goBack,
    isLoading,
    productName,
    qrBackgroundSession,
    selectedAssets,
    selectedAssetCount,
    selectCQRLayout,
    state,
  } = props;
  const classes = useStyles();
  const { eccLevel, format, magnification, version } = state;
  const assetId = batchAsset
    ? props.assetId
    : selectedAssets[Object.keys(selectedAssets)[0]]
    ? selectedAssets[Object.keys(selectedAssets)[0]].assetId
    : "";
  return (
    <Grid container id="delivery">
      {/* Information */}
      <Grid item xs={12}>
        <Box pt={5} pb={5} pl={2} pr={2}>
          <Card>
            <CardContent>
              <Grid container>
                {/* Header */}
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <h3>Information</h3>
                  </Box>
                </Grid>

                {/* Sub Header */}
                <Grid item xs={12}>
                  <Box textAlign="center" mb={2}>
                    Please Review All Information and View the CQR Preview
                  </Box>
                </Grid>

                {/* Asset Info */}
                <Grid item xs={12}>
                  {batchAsset ? (
                    <Box display="flex" justifyContent="center">
                      <Box width="25%">
                        {/* Batch Id */}
                        <div className={classes.item}>
                          <span className={classes.itemText}>Batch ID:</span>
                          {batchName}
                        </div>

                        {/* Product Name */}
                        <div className={classes.item}>
                          <span className={classes.itemText}>
                            Product Name:
                          </span>
                          {productName}
                        </div>

                        {/* Unit of Measure */}
                        <div className={classes.item}>
                          <span className={classes.itemText}>
                            Unit of Measure:
                          </span>
                          {assetType}
                        </div>

                        {/* Count */}
                        <div className={classes.item}>
                          <span className={classes.itemText}>Count:</span>
                          {selectedAssetCount}
                        </div>
                      </Box>
                      <Box width="25%">
                        {/* ECC Level */}
                        <div className={classes.item}>
                          <span className={classes.itemText}>ECC Level:</span>
                          Level{" "}
                          <span className={classes.textUpperCase}>
                            {eccLevel}
                          </span>
                        </div>

                        {/* Image Format */}
                        <div className={classes.item}>
                          <span className={classes.itemText}>
                            Image Format:
                          </span>
                          <span className={classes.textUpperCase}>
                            {format}
                          </span>
                        </div>

                        {/* QR Version */}
                        <div className={classes.item}>
                          <span className={classes.itemText}>QR Version:</span>
                          {version}
                        </div>
                      </Box>
                      <Box width="25%">
                        {/* Qr Size */}
                        <div className={classes.item}>
                          <span className={classes.itemText}>QR Size:</span>
                          {magnification} pixel/cell
                        </div>
                      </Box>
                    </Box>
                  ) : (
                    <Box display="flex" justifyContent="center">
                      <Box width="25%">
                        {/* Count */}
                        <div className={classes.item}>
                          <span className={classes.itemText}>Count:</span>
                          {Object.keys(selectedAssets).length}
                        </div>

                        {/* Asset Tag */}
                        <Box display="flex" mt={2} mb={2}>
                          <div>
                            {Object.keys(selectedAssets).map((asset, index) => {
                              const { assetMode, assetTag, tag } = selectedAssets[asset];
                              return (
                                <div key={`${asset}-${index}`}>
                                  <span className={classes.itemText}>{assetMode === "Inventory" ? "Inventory Tag" : "Asset Tag"}:</span>
                                  {tag || assetTag || ""}
                                </div>
                              );
                            })}
                          </div>
                        </Box>
                      </Box>
                      <Box width="25%" style={{ width: "25%" }}>
                        {/* Level */}
                        <div className={classes.item}>
                          <span className={classes.itemText}>ECC Level:</span>
                          Level{" "}
                          <span className={classes.textUpperCase}>
                            {eccLevel}
                          </span>
                        </div>
                        {/* Image Format */}
                        <div className={classes.item}>
                          <span className={classes.itemText}>
                            Image Format:
                          </span>
                          <span className={classes.textUpperCase}>
                            {format}
                          </span>
                        </div>
                        QR Version
                        <div className={classes.item}>
                          <span className={classes.itemText}>QR Version:</span>
                          {version}
                        </div>
                      </Box>

                      {/* QR Size */}
                      <Box width="25%">
                        <div className={classes.item}>
                          <span className={classes.itemText}>QR Size:</span>
                          {magnification} pixel/cell
                        </div>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      {/* CQR Preview */}
      <Grid item xs={12}>
        <Box pt={5} pb={5} mt={3} mb={3} pl={2} pr={2} textAlign="center">
          <Card>
            <CardContent>
              <h3>CQR Preview</h3>
              {qrBackgroundSession.qrBackgroundSessionId.length > 0 ? (
                <img
                  alt="cqr"
                  src={qrBackgroundSession.image}
                  style={{ border: "#E6E7E8 2px solid", maxWidth: "100%" }}
                />
              ) : selectCQRLayout.value ? (
                <img
                  alt="cqr"
                  src={`${props.apiUrl}assets/${assetId}/qrCode?urlBase=https://qr.locatorx.com&target=lxproductscan&eccLevel=${eccLevel}&magnification=${magnification}&version=${version}&qrBackground=${selectCQRLayout.label}`}
                  style={{ border: "#E6E7E8 2px solid", maxWidth: "100%" }}
                />
              ) : (
                <img
                  alt="cqr"
                  src={`${props.apiUrl}assets/${assetId}/qrCode?urlBase=https://qr.locatorx.com&target=lxproductscan&eccLevel=${eccLevel}&magnification=${magnification}&version=${version}&showTag=true`}
                  style={{ border: "#E6E7E8 2px solid", maxWidth: "100%" }}
                />
              )}
            </CardContent>
          </Card>
        </Box>
      </Grid>

      {/* Download CQRs */}
      <Grid item xs={12}>
        <Box pt={5} pb={5} pl={2} pr={2} textAlign="center">
          <Card>
            <CardContent>
              <h3>Download CQRs</h3>
              <h6>
                You can download a ZIP file of images in the format you selected
                by clicking the Download button
              </h6>
              <Box mt={2}>
                <MaterialUiButton
                  label="Download"
                  onClick={(value) => {
                    isLoading(true);
                    downloadCQR(value, {
                      ...props,
                      isLoading: isLoading,
                      onSuccess: props.onSuccess,
                    });
                  }}
                  size="medium"
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      {/* Go Back Button */}
      <Grid item xs={12}>
        <Box display="flex" mt={3} mb={3} textAlign="center">
          <button
            className="cqrback"
            onClick={() => {
              goBack();
            }}
            style={{ border: 0 }}
          >
            <i className="fas fa-arrow-circle-left fa-2x"></i>
          </button>
        </Box>
      </Grid>
    </Grid>
  );
}
