import { useEffect, useState } from "react";
import { Autocomplete, Box, InputLabel, Grid, TextField } from "@mui/material/";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { naturalSort } from "../../../utils/naturalSort";
import { onSubmit } from "./utils";
import CurrencyTextBox from "../../../components/Forms/FieldTypes/CurrencyTextBox";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import MomentUtils from "@date-io/moment";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";
import GenerateCQRContent from "../../../components/Layout/AppBar/GenerateCQRContent";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  button:{
    marginLeft:"1rem"
  },
  label: {
    color: "#6D6E70 !important",
    fontFamily: "Lato !important",
    fontWeight: "bold !important",
    lineHeight: "1 !important",
  },
  inputContainer: {
    margin: ".5rem 0 !important",
    padding: "0 .5rem",
  },
  inputField: {
    width: "100%",
  },
}));

export default function InventoryContent(props) {
  const { closeModal, customs, facilities, organization } = props;
  const [generateCQR, setGenerateCQR] = useState(false);
  const [newInventoryItem, setNewInventoryItem] = useState("");
  const { customFieldsList = [] } = organization;

  const classes = useStyles();

  const [
    {
      category,
      description,
      inventoryItemTag,
      note,
      quantity,
      selectedFacility,
      status,
      timeCreatedUser,
      timeNeeded,
    },
    setState,
  ] = useState({
    category: { error: false, id: "category", label: "", value: "" },
    description: { error: false, id: "description", value: "" },
    inventoryItemTag: { error: false, id: "inventoryItemTag", value: "" },
    note: { id: "note", value: "" },
    quantity: { error: false, id: "quantity", value: "" },
    selectedFacility: {
      error: false,
      label: "",
      id: "selectedFacility",
      value: "",
    },
    status: { error: false, id: "status", value: "" },
    timeCreatedUser: { id: "timeCreatedUser", value: "" },
    timeNeeded: { id: "timeNeeded", value: "" },
  });
  const [snackbarState, setSnackbarState] = useState({
    alertModalContent: "",
    alertModalOpen: false,
    alertModalSeverity: "success",
    modalOpen: false,
  });

  const classifications = {};
  for (let currentClassification in props.classifications) {
    const classificationObj = props.classifications[currentClassification];
    if (classificationObj.assetMode === "Inventory") {
      classifications[currentClassification] = classificationObj;
    }
  }

  const [availableEvents, setAvailableEvents] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [inventoryClassifications, setInventoryClassifications] = useState({
    ...classifications,
  });
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [customFields, setCustomFields] = useState({});
  const handleAlertModal = (content) => {
    const {
      alertModalContent,
      alertModalSeverity = "success",
      alertModalTitle,
    } = content;

    setSnackbarState((prevState) => ({
      ...prevState,
      alertModalContent,
      alertModalOpen: true,
      alertModalSeverity,
      alertModalTitle,
    }));
  };
  const renderedCustomFields = Object.keys(customFields).map(
    (element, index) => {
      if (customFields[element].customId) {
        let {
          dataType,
          error = false,
          customId,
          name = "",
          value = "",
        } = customFields[element];

        switch (dataType) {
          case "Currency":
            return (
              <Grid
                className={classes.inputContainer}
                item
                key={`${customId}-${index}`}
                xs={4}
              >
                <CurrencyTextBox
                  fullWidth={true}
                  error={error}
                  label={name}
                  onChange={(event) => {
                    setCustomFields((prevState) => ({
                      ...prevState,
                      [name]: {
                        ...prevState[name],
                        error: false,
                        value: event.target.value,
                      },
                    }));
                  }}
                  size="large"
                  value={value}
                  variant="outlined"
                />
              </Grid>
            );
          case "Date":
            return (
              <Grid
                className={classes.inputContainer}
                item
                xs={4}
                key={`${customId}-${index}`}
              >
                <InputLabel className={classes.label}>{name}</InputLabel>
                <MuiPickersUtilsProvider utils={MomentUtils} variant="outlined">
                  <KeyboardDatePicker
                    autoOk
                    allowKeyboardControl={false}
                    className={classes.inputField}
                    disableToolbar
                    format="MM/DD/yyyy"
                    helperText={error ? "Required Field" : ""}
                    id={`date-picker-inline-${customId}`}
                    inputVariant="outlined"
                    error={error}
                    invalidDateMessage={`${error ? "Required Field" : ""}`}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    label={null}
                    onChange={(event) => {
                      const value = event ? event.format("MM/DD/yyyy") : "";
                      setCustomFields((prevState) => ({
                        ...prevState,
                        [name]: {
                          ...prevState[name],
                          error: false,
                          value,
                        },
                      }));
                    }}
                    value={value.length > 0 ? value : null}
                    variant="inline"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            );
          default:
            return (
              <Grid
                className={classes.inputContainer}
                item
                key={`${customId}-${index}`}
                xs={4}
              >
                <InputLabel className={classes.label}>{name}</InputLabel>
                <TextField
                  className={classes.inputField}
                  error={error}
                  helperText={error ? "Required Field" : ""}
                  label={null}
                  onChange={(event) => {
                    const value = event.target ? event.target.value : "";
                    setCustomFields((prevState) => ({
                      ...prevState,
                      [name]: { ...prevState[name], value, error: false },
                    }));
                  }}
                  type={`${dataType === "Numeric" ? "number" : ""}`}
                  value={value}
                  variant="outlined"
                />
              </Grid>
            );
        }
      }
      return null;
    }
  );
  const renderClassificationFields = Object.keys(inventoryClassifications).map(
    (parent, index) => {
      const { formOptions, name } = inventoryClassifications[parent];
      const childrenOptions = [];

      // Populates the select options
      Object.keys(formOptions).forEach((child) => {
        childrenOptions.push({
          label: formOptions[child].label,
          parentId: formOptions[child].referenceId,
          value: formOptions[child].formOptionId,
        });
      });

      return (
        <Grid
          className={classes.inputContainer}
          item
          key={`${name}-${index}`}
          xs={4}
        >
          <InputLabel className={classes.label}>{name}</InputLabel>
          <Autocomplete
            id={name}
            options={childrenOptions.sort((a, b) =>
              naturalSort(a.label, b.label)
            )}
            onChange={(event, value) => {
              if (value) {
                const { value: itemValue = "" } = value;
                setInventoryClassifications((prevState) => ({
                  ...prevState,
                  [name]: {
                    ...prevState[name],
                    selectedValue: itemValue,
                   // Im revisiting this code. We should of written the onSubmit to break apart 
                   //the value. Instead of doing the itemValue variable. But alas, deadlines. 
                   //Im adding 'value' to this. That way we can actually clear this field when 
                   //the clear button is clicked
                   value
                  },
                }));
              } else {
                setInventoryClassifications((prevState) => ({
                  ...prevState,
                  [name]: {
                    ...prevState[name],
                    selectedValue: value,
                    value
                  },
                }));
              }
            }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label={null} variant="outlined" />
            )}
            value={inventoryClassifications[name].value || {label:"",value:""}}

          />
        </Grid>
      );
    }
  );

  function validateFields() {
    let isValidated = true;

    const fieldsToValidate = [
      inventoryItemTag,
      category,
      description,
      selectedFacility,
      status,
    ];

    // if a field is empty, set its error value to true,
    fieldsToValidate.forEach((currentField) => {
      const { value, id } = currentField;

      if (value.length === 0) {
        setState((prevState) => {
          return {
            ...prevState,
            [id]: { ...prevState[id], error: true, id, value },
          };
        });
        isValidated = false;
      }
    });

    // if custom field is required and field is empty, set its error value to true
    Object.keys(customFields).forEach((customField) => {
      const { value, name, required } = customFields[customField];
      if (required && value?.length === 0) {
        isValidated = false;
        setCustomFields((prevState) => {
          return {
            ...prevState,
            [name]: { ...prevState[name], error: true },
          };
        });
      }
    });

    if (isValidated) {
      let selectedInventoryClassifications = {};
      // Handles Classifications
      Object.keys(inventoryClassifications).forEach((item) => {
        if (
          inventoryClassifications[item].selectedValue !== null &&
          inventoryClassifications[item].selectedValue !== undefined &&
          inventoryClassifications[item].selectedValue.length > 0
        ) {
          // Here we find the parent classification. Then do a .find in the formOptions to pull the childs label
          const selectedItemLabel = inventoryClassifications[
            item
          ]?.formOptions?.find(
            (element) =>
              element.formOptionId ===
              inventoryClassifications[item].selectedValue
          )?.label;

          selectedInventoryClassifications[item] = selectedItemLabel;
        }
      });

      onSubmit(customFields, handleAlertModal, props, {
        category,
        description,
        inventoryItemTag,
        note,
        quantity,
        selectedFacility,
        selectedInventoryClassifications,
        status,
        timeCreatedUser,
        timeNeeded,
      }).then((response) => {
        // reset state
        setState({
          inventoryItemTag: {
            error: false,
            id: "inventoryItemTag",
            value: "",
          },
          category: {
            value: "",
            error: false,
            id: "category",
          },
          description: {
            error: false,
            id: "description",
            value: "",
          },
          note: { id: "note", value: "" },
          quantity: {
            error: false,
            id: "quantity",
            value: "",
          },
          selectedFacility: {
            error: false,
            id: "selectedFacility",
            value: "",
          },
          status: {
            error: false,
            id: "status",
            value: "",
          },
          timeCreatedUser: {
            id: "timeCreatedUser",
            value: "",
          },
          timeNeeded: {
            id: "timeNeeded",
            value: "",
          },
        });
        // Delivers the InventoryItemId to the parent component and tells it to switch
        // to the next layout
        setNewInventoryItem(response);
        setGenerateCQR(true);
      });
    }
  }

  function clearFields() {
    // Clear Basic Fields
    setState({
      category: { error: false, label: "", id: "category", value: "" },
      description: { error: false, id: "description", value: "" },
      inventoryItemTag: { error: false, id: "inventoryItemTag", value: "" },
      note: { id: "note", value: "" },
      quantity: { error: false, id: "quantity", value: "" },
      selectedFacility: {
        error: false,
        label: "",
        id: "selectedFacility",
        value: "",
      },
      status: { error: false, id: "status", value: "" },
      timeCreatedUser: { id: "timeCreatedUser", value: "" },
      timeNeeded: { id: "timeNeeded", value: "" },
    });

    // Clear Custom Fields
    setCustomFields((prevState) => {
      const newState = prevState;
      Object.keys(newState).forEach(
        (item) =>
          (newState[item] = {
            ...newState[item],
            error: false,
            required: false,
            value: "",
          })
      );

      // did you know if you returned newState instead of {...newState} this whole clear function breaks? Wild
      return { ...newState };
    });

    // Clear Classifications
    setInventoryClassifications((prevState) => {
      const newState = prevState;
      Object.keys(newState).forEach(
        (item) =>
          (newState[item] = {
            ...newState[item],
            error: false,
            required: false,
            value: "",
            selectedValue: ""
          })
      );

      // did you know if you returned newState instead of {...newState} this whole clear function breaks? Wild
      return { ...newState };
    });
  }

  useEffect(() => {
    const { eventTypesMap = {}, categoriesList = [] } = organization;
    const inventoryCategoriesList = categoriesList.filter(
      (category) => category.assetMode === "Inventory"
    );

    const availableEventItems = [];
    const categoryOptionItems = [];
    const facilityOptionItems = [];
    const fields = {};
    const fieldValues = {};

    const inventoryCustoms = customs.filter(
      (custom) => custom.assetMode === "Inventory"
    );

    inventoryCustoms.forEach((inventoryCustom) => {
      fields[inventoryCustom.name] = {
        ...inventoryCustom,
        required: false,
        error: false,
        value: "",
      };
      fieldValues[inventoryCustom.customId] = "";
    });

    // Set Facility Options

    facilityOptionItems.push({ label: "", value: "" });

    Object.keys(facilities)
      .sort((a, b) => {
        return facilities[a].name?.localeCompare(facilities[b].name);
      })
      .forEach((element) => {
        const { name, facilityId } = facilities[element];
        facilityOptionItems.push({ label: name, value: facilityId });
      });

    // Set Category Options
    [...inventoryCategoriesList]
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      })
      .forEach((element) => {
        categoryOptionItems.push({ id: element.id, label: element.id });
      });

    // Add a blank value as one of the options
    categoryOptionItems.unshift({ id: "", label: "" });

    // Add a blank value to the events
    availableEventItems.push({ value: "" });

    Object.keys(eventTypesMap)
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .forEach((element) => {
        if (element === "Tag Destroyed") {
          return null;
        } else {
          availableEventItems.push({ value: element });
        }
      });

    if (Object.keys(fields).length > 0) {
      setCustomFields(fields);
    }

    setAvailableEvents(availableEventItems);
    setCategoryOptions(categoryOptionItems);
    setFacilityOptions(facilityOptionItems);
  }, [customs, facilities, organization]);

  return (
    <Grid container>
      {/* Success / Error handling Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarState.alertModalOpen}
        onClose={snackbarState.closeModal}
      >
        <MuiAlert
          onClose={snackbarState.closeModal}
          severity={snackbarState.alertModalSeverity}
        >
          {snackbarState.alertModalContent}
        </MuiAlert>
      </Snackbar>

      {/* The create cqr dialog appears after the creation process is done */}
      {generateCQR ? (
        <GenerateCQRContent
          closeModal={() => setGenerateCQR(false)}
          inventoryItem={newInventoryItem}
          open={generateCQR}
          setGenerateCQR={setGenerateCQR}
        />
      ) : null}
      <Grid item xs={12}>
        <Grid container>
          {/* Inventory Tag */}
          <Grid className={classes.inputContainer} item xs={4}>
            <SimpleTextField
              error={inventoryItemTag.error}
              helperText={inventoryItemTag.error ? "Required Field" : ""}
              id="outlined-basic"
              label="Inventory Tag"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  inventoryItemTag: {
                    ...prevState.inventoryItemTag,
                    value,
                    error:
                      prevState.inventoryItemTag.error && value.length > 0
                        ? false
                        : prevState.inventoryItemTag.error,
                  },
                }));
              }}
              size="medium"
              value={inventoryItemTag.value}
              variant="outlined"
            />
          </Grid>

          {/* Description */}
          <Grid className={classes.inputContainer} item xs={4}>
            <SimpleTextField
              error={description.error}
              helperText={description.error ? "Required Field" : ""}
              id="outlined-basic"
              label="Description"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  description: {
                    ...prevState.description,
                    value,
                    error:
                      prevState.description.error && value.length > 0
                        ? false
                        : prevState.description.error,
                  },
                }));
              }}
              size="medium"
              value={description.value}
              variant="outlined"
            />
          </Grid>
          
          {/* Quantity */}
          <Grid className={classes.inputContainer} item xs={4}>
            <SimpleTextField
              error={quantity.error}
              helperText={quantity.error ? "Required Field" : ""}
              id="outlined-basic"
              label="Quantity"
              onChange={(event) => {
                const value = event.target && !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : "";
                setState((prevState) => ({
                  ...prevState,
                  quantity: {
                    ...prevState.quantity,
                    value,
                    error:
                      prevState.quantity.error 
                        ? false
                        : prevState.quantity.error,
                  },
                }));
              }}
              size="medium"
              type="number"
              value={quantity.value}
              variant="outlined"
            />
          </Grid>

          {/* Category */}
          <Grid className={classes.inputContainer} item xs={4}>
            <InputLabel className={classes.label}>Category</InputLabel>
            <Autocomplete
              id="category"
              options={categoryOptions}
              onChange={(event, value) => {
                if (value) {
                  const { id = "" } = value;
                  // Sets the value in state
                  setState((prevState) => ({
                    ...prevState,
                    category: {
                      ...prevState.category,
                      label: id,
                      value: id,
                      error:
                        prevState.category.error && id.length > 0
                          ? false
                          : prevState.category.error,
                    },
                  }));

                  // Checks custom fields to see if any fields need to switched to required
                  // The else statement sets everything to default. In the case they switch
                  // to a category that does not have any custom fields set.

                  const isValidCustomCategory =
                    organization.categoriesList.find((category) => {
                      return category.id === id;
                    });

                  if (isValidCustomCategory) {
                    const { requiredFields = [] } = isValidCustomCategory;
                    setCustomFields((prevState) => {
                      let newState = { ...prevState };
                      requiredFields.forEach((field) => {
                        const { required, label } = field;
                        newState[label] = {
                          ...newState[label],
                          required,
                          error: false,
                        };
                      });
                      return newState;
                    });
                  }
                }
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={category.error}
                  helperText={category.error ? "Required Field" : ""}
                  label={null}
                  size="medium"
                  variant="outlined"
                />
              )}
              value={category}
            />
          </Grid>

          {/* Facility */}
          <Grid className={classes.inputContainer} item xs={4}>
            <InputLabel className={classes.label}>Facility</InputLabel>
            <Autocomplete
              id="facility"
              options={facilityOptions}
              onChange={(event, value) => {
                if (value) {
                  const { label, value: itemValue = "" } = value;
                  setState((prevState) => ({
                    ...prevState,
                    selectedFacility: {
                      ...prevState.selectedFacility,
                      label,
                      error:
                        prevState.selectedFacility.error && itemValue.length > 0
                          ? false
                          : prevState.selectedFacility.error,
                      value: itemValue,
                    },
                  }));
                }
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={selectedFacility.error}
                  helperText={selectedFacility.error ? "Required Field" : ""}
                  label={null}
                  variant="outlined"
                />
              )}
              value={selectedFacility}
            />
          </Grid>

          {/* Status */}
          <Grid className={classes.inputContainer} item xs={4}>
            <InputLabel className={classes.label}>Status</InputLabel>
            <Autocomplete
              id="status"
              options={availableEvents}
              onChange={(event, value) => {
                if (value) {
                  const { value: itemValue = "" } = value;
                  setState((prevState) => ({
                    ...prevState,
                    status: {
                      ...prevState.status,
                      value: itemValue,
                      error:
                        prevState.status.error && itemValue.length > 0
                          ? false
                          : prevState.status.error,
                    },
                  }));
                }
              }}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={status.error}
                  helperText={status.error ? "Required Field" : ""}
                  label={null}
                  variant="outlined"
                />
              )}
              value={status}
            />
          </Grid>

          {/* timeCreatedUser */}
          <Grid
            className={classes.inputContainer}
            item
            xs={4}
            key={`timeCreatedUser`}
          >
            <InputLabel className={classes.label}>Create Date</InputLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} variant="outlined">
              <KeyboardDatePicker
                autoOk
                allowKeyboardControl={false}
                className={classes.inputField}
                disableToolbar
                format="MM/DD/yyyy"
                id={`date-picker-inline-timeCreatedUser`}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                label={null}
                onChange={(event) => {
                  const value = event ? event.format("MM/DD/yyyy") : "";

                  setState((prevState) => ({
                    ...prevState,
                    timeCreatedUser: {
                      ...prevState.timeCreatedUser,
                      value,
                    },
                  }));
                }}
                value={
                  timeCreatedUser.value?.length > 0
                    ? timeCreatedUser.value
                    : null
                }
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {/* timeNeeded */}
          <Grid
            className={classes.inputContainer}
            item
            xs={4}
            key={`timeNeeded`}
          >
            <InputLabel className={classes.label}>Need Date</InputLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} variant="outlined">
              <KeyboardDatePicker
                autoOk
                allowKeyboardControl={false}
                className={classes.inputField}
                disableToolbar
                format="MM/DD/yyyy"
                id={`date-picker-inline-timeNeeded`}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                label={null}
                onChange={(event) => {
                  const value = event ? event.format("MM/DD/yyyy") : "";

                  setState((prevState) => ({
                    ...prevState,
                    timeNeeded: {
                      ...prevState.timeNeeded,
                      value,
                    },
                  }));
                }}
                value={timeNeeded.value?.length > 0 ? timeNeeded.value : null}
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {/* Custom Fields */}
          {renderedCustomFields}

          {/* Classifications */}
          {renderClassificationFields}

          {/* Inventory Details */}
          <Grid className={classes.inputContainer} item xs={12}>
            <SimpleTextField
              multiline
              id="outlined-basic"
              label="Inventory Details - optional"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  note: { ...prevState.note, label: value, value: value },
                }));
              }}
              variant="outlined"
              value={note.label}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          className={classes.inputContainer}
          textAlign="right"
          width="100%"
        >
          {/* Clear Button */}
          <MaterialUiButton
            className={classes.button}
            color="cancel"
            label="Clear"
            onClick={() => {
              clearFields();
            }}
            variant="outlined"
          />

          {/* Submit Button */}
          <MaterialUiButton
            className={classes.button}
            color="primary"
            label="submit"
            onClick={() => {
              validateFields();
            }}
            variant="contained"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
