import { lazy, Suspense, useEffect, useState } from "react";
import { Box, Grid, Tooltip } from "@material-ui/core";
import { deleteZone } from "./API/api";
import { isEqual } from "lodash";
import { updateFacilities } from "../../../globalStore/slices/organization/organizationSlice";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModalContent from "../../../components/Modals/ConfirmationModalContent";
import CreateZone from "./CreateZone/CreateZone";
import EditZone from "./EditZone/EditZone";
import HoverIconButton from "../../../components/ReusedComponents/HoverIconButton";
import Loading from "../../../components/Loading/Loading";
import MaterialConfirmationModal from "../../../components/Modals/MaterialConfirmationModal";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import ZoneDetail from "./ZoneDetail/ZoneDetail";

// Component uses xlxs which is very heavy, which is why we are using lazy/Suspense
const ZonesTable = lazy(() => import("./ZonesTable/ZonesTable"));

export default function Zones(props) {
  const { apiUrl, facilities, token, permissions, view, setView } = props;
  const [confirm, setConfirm] = useState({
    confirmShow: false,
    text: "",
    color: "",
    icon: "",
  });
  const [dialog, setDialog] = useState({
    dialogTitle: "",
    dialogShow: false,
    selectedZone: {},
  });
  const zones = useSelector((state) => state.organization.zones, isEqual);
  const organization = useSelector(
    (state) => state.organization.organization,
    isEqual
  );
  const { zoneCategories = [] } = organization?.propertiesMap || {};
  const { createLocation } = permissions;
  const { facility = {} } = view;
  const [state, setState] = useState({
    facility: facility,
    zones: [],
    zoneHashMap: {},
    selectedZones: {},
  });
  const dispatchGlobal = useDispatch();

  const handleDelete = (id) => {
    deleteZone({ apiUrl, token }, id)
      .then((res) => {
        console.log(res);
        if (res.success) {
          const { zones: selectedFacilityZones } = facility;
          // We filter out the deleted zone
          const filteredZones = selectedFacilityZones.filter(
            (element) => element.zoneId !== id
          );

          dispatchGlobal(
            updateFacilities({
              facilityId: state.facility.facilityId,
              body: {
                ...state.facility,
                zones: filteredZones,
              },
            })
          );

          handleConfirmationModal("Zone Successfully Deleted");
          dialogClose();
        }
      })
      .catch((err) => {
        handleConfirmationModal(
          `There was a problem deleting the selected zone: ${err}`,
          true,
          "fas fa-times-circle",
          3000
        );
      });
  };

  const handleConfirmationModal = (
    confirmationText,
    color,
    icon,
    delay = 750
  ) => {
    setConfirm({
      ...confirm,
      confirmShow: true,
      text: confirmationText,
      color: color,
      icon: icon,
    });
    setTimeout(function () {
      setConfirm({
        ...confirm,
        confirmShow: false,
        text: "",
        color: "",
        icon: "",
      });
    }, delay);
  };

  const dialogClose = () =>
    setDialog({
      ...dialog,
      dialogShow: false,
      dialogTitle: "",
      selectedZone: {},
    });

  const notificationModalClose = () => {
    setConfirm({ ...confirm, confirmShow: false });
  };

  const switchDialog = (dialog) => {
    switch (dialog) {
      case "Zone Detail":
        return (
          <ZoneDetail
            handleClose={() => {
              dialogClose();
            }}
            open={true}
            token={token}
            apiUrl={apiUrl}
            selectedZone={selectedZone}
            notificationModal={handleConfirmationModal}
            zonesMap={zones || {}}
            facility={facility}
          />
        );
      case "Create Zone":
        return (
          <CreateZone
            apiUrl={apiUrl}
            facility={state.facility}
            notificationModal={handleConfirmationModal}
            onHide={dialogClose}
            onSuccess={(addZoneResponse) => {
              // Adding the new zone to the facility in the redux store
              dispatchGlobal(
                updateFacilities({
                  facilityId: view.facility.facilityId,
                  body: {
                    ...view.facility,
                    zones: [...view.facility.zones, addZoneResponse],
                  },
                })
              );
            }}
            token={token}
            zoneCategories={zoneCategories}
            zoneHashMap={state.zoneHashMap}
            zones={state.zones}
          />
        );
      case "Edit Zone":
        return (
          <EditZone
            apiUrl={apiUrl}
            notificationModal={handleConfirmationModal}
            onHide={dialogClose}
            onSuccess={(id, editResponse) => {
              const zoneArray = [...state.facility.zones];
              const indexOfModifiedZone = zoneArray.findIndex(
                (element) => element.zoneId === id
              );

              zoneArray[indexOfModifiedZone] = editResponse;

              dispatchGlobal(
                updateFacilities({
                  facilityId: state.facility.facilityId,
                  body: { ...state.facility, zones: zoneArray },
                })
              );
            }}
            selectedZone={selectedZone}
            token={token}
            zoneCategories={zoneCategories}
            zoneHashMap={state.zoneHashMap}
            zones={state.zones}
          />
        );
      case "Delete Zone":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${selectedZone.name}? This action cannot be
            undone.`}
            handleSubmit={() => {
              handleDelete(selectedZone.zoneId);
            }}
            handleCancel={() => {
              dialogClose();
            }}
          />
        );
      default:
        return;
    }
  };

  const { dialogTitle, dialogShow, selectedZone } = dialog;

  useEffect(() => {
    const zoneHashMap = {};
    const selectedFacility = facilities[view.facility.facilityId];

    selectedFacility.zones.forEach((zone) => {
      zoneHashMap[zone.name] = zone;
    });

    setState((prevState) => ({
      ...prevState,
      facility: selectedFacility,
      zones: selectedFacility.zones,
      zoneHashMap,
    }));
  }, [facilities, view.facility.facilityId]);

  return (
    <Box mt={3}>
      <Grid container>
        <ModalDialog
          content={switchDialog(dialogTitle)}
          handleClose={dialogClose}
          open={dialogShow}
          title={dialogTitle}
        />
        <MaterialConfirmationModal
          content={confirm.text}
          closeModal={notificationModalClose}
          modalOpen={confirm.confirmShow}
          severity={confirm.color ? "error" : "success"}
          variant="filled"
        />
        <Grid item xs={12}>
          <Box mt={3} textAlign="left">
            <h3>{state.facility.name} Zones</h3>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            textAlign="right"
          >
          
              <HoverIconButton
                handleClick={() =>
                  setView({
                    facility: {},
                    viewZones: false,
                  })}
                icon="fas fa-arrow-left"
                iconDirection="left"
                text="Back to Facilities"
              />

            {createLocation ? (
              <Tooltip title="Create a new zone for the selected facility.">
                <Box ml={2}>
                  <MaterialUiButton
                    label="Create Zone"
                    onClick={() =>
                      setDialog({
                        ...dialog,
                        dialogShow: true,
                        dialogTitle: "Create Zone",
                      })
                    }
                    size="small"
                  />
                </Box>
              </Tooltip>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Suspense fallback={<Loading />}>
            <ZonesTable
              diaog={dialog}
              handleConfirmationModal={handleConfirmationModal}
              permissions={permissions}
              setDialog={setDialog}
              setState={setState}
              setView={setView}
              state={state}
            />
          </Suspense>
        </Grid>
      </Grid>
    </Box>
  );
}
