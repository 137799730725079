const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const searchHistories = async (props, state) => {
  const { token, organizationId } = props;
  const { assetId, filters = {}, page = 0 } = state;
  let { allDevicesMap = {} } = filters;

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must_not: [
          {
            term: {
              event: "External Scanned",
            },
          },
          {
            term: {
              event: "Proximity",
            },
          },
          {
            term: {
              event: "Moving Proximity",
            },
          },
        ],
        must: [
          // Give us assets from this organization
          {
            term: {
              organization_id: organizationId,
            },
          },

          // No devices please
          {
            bool: {
              must_not: [
                {
                  nested: {
                    path: "device",
                    query: {
                      exists: {
                        field: "device.status",
                      },
                    },
                  },
                },
              ],
            },
          },

          // No batches either
          {
            bool: {
              must_not: [
                {
                  exists: {
                    field: "batch_id",
                  },
                },
              ],
            },
          },

          // Only Inventory Assets
          {
            term: {
              asset_mode: "Inventory",
            },
          },
        ],
      },
    },

    limit: 25000,
    page,
    sort: "time_of_log DESC",
  };

  // Asset Id - In the case we are looking for histories from a certain assetId
  if (assetId) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_id: assetId,
      },
    });
  }

  // Device Filter - build filter query string from all the asset ids found in the allDevicesMap to filter out asset histories that may have come from devices
  const filterDevicesString = Object.keys(allDevicesMap).map((deviceId) => {
    return deviceId;
  });

  elasticQuery.elasticSearchQuery.bool.must_not.push({
    terms: {
      asset_id: filterDevicesString,
    },
  });

  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error:
          "Failed to fetch asset history data, please contact system administrator.",
      };
    });

  return results;
};

export const saveFilterSettings = async (props, filters) => {
  const { token, appUserId } = props;
  const payload = {
    propertiesMap: {
      inventoryHistoryTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${appUserId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const inventoryHistory = {
  searchHistories,
  saveFilterSettings,
};
