import { useCallback, useEffect, useState } from "react";
import { checkFacility, getZoneHashMap, updateFacility } from "./api";
import { CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { searchRadios } from "../../../utils/API/Radios/Radios";
import { Redirect } from "react-router-dom";
import Card from "@material-ui/core/Card";
import ConfigureZonesModalContent from "./ConfigureZonesModalContent";
import DefineGrid from "./DefineGrid";
import EditNode from "../ConfigureZone/EditNode/EditNode";
import FacilityImage from "./FacilityImage";
import FacilityImageUpload from "./FacilityImageUpload";
import Grid from "@material-ui/core/Grid";
import HoverIconButton from "../../../components/ReusedComponents/HoverIconButton";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import MuiAlert from "@material-ui/lab/Alert";
import NoFacilityPresent from "./NoFacilityPresent";
import ResetFacilityImage from "./ResetFacilityImage";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles((theme) => ({
  card: {
    alignItems: "center",
    display: "flex",
    minHeight: "50vh",
    width: "100%",
  },
  button: {
    margin: "0 1rem",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    placeItems: "center",
  },
  loading: {
    textAlign: "center",
  },
  image: {
    pointerEvents: "none",
    position: "absolute",
  },
  overViewContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "1rem 0",
  },
  titleContainer: {
    display: "flex",
    marginBottom: "1rem",
  },
}));

export default function FacilityOverview(props) {
  const { history = {} } = props;
  const { location = {} } = history;
  const { state: locationState = {} } = location;
  const { selectedFacility = {} } = locationState;
  const { facilityId, name = "" } = selectedFacility;
  const classes = useStyles();
  const [state, setState] = useState({
    fakeChildren: [
      {
        name: "Dan Radio 1",
        x: 4.0132,
        y: 0,
      },
      {
        name: "Dan Radio 2",
        x: 4.0132,
        y: 3.1496,
      },
      {
        name: "Dan Radio 3",
        x: 5.715,
        y: 3.1496,
      },
    ],
    facilityImage: null,
    facilityImageId: null,
    farPoint: { x: 0, y: 0, disablePlunger: false },
    grid: { x: 0, y: 0 },
    loading: true,
    selectedNode: {},
    zoneHashMap: {},
    zeroPoint: { x: 0, y: 0, disablePlunger: false },
  });
  const { farPoint, zeroPoint } = state;
  const [snackbarState, setSnackbarState] = useState({
    content: null,
    error: false,
    open: false,
    severity: "success",
  });
  const [modalState, setModalState] = useState({
    modalShow: false,
    title: "",
  });
  const [redirect, setRedirect] = useState({
    isRedirect: false,
    state: {},
    pathName: "",
  });
  const switchModal = (modal) => {
    switch (modal) {
      case "Configure Zones":
        return (
          <ConfigureZonesModalContent
            setModalState={setModalState}
            setRedirect={setRedirect}
            selectedFacility={selectedFacility}
          />
        );
      case "Define Grid":
        return (
          <DefineGrid
            {...props}
            facilityId={facilityId}
            handleSnackbar={handleSnackbar}
            setModalState={setModalState}
            setState={setState}
            state={state}
          />
        );
      case "Mount Point Information":
        return (
          <EditNode
            {...props}
            dialogClose={() => {
              setModalState((prevState) => ({
                ...prevState,
                modalShow: false,
              }));
            }}
            handleAlertModal={() => {}}
            onSuccess={setFacility}
            showTitle={true}
            state={state}
            zoneInfo={state.zoneHashMap[state.selectedNode.zoneId]}
          />
        );
      case "Reset Facility Image":
        return (
          <ResetFacilityImage
            {...props}
            facilityId={facilityId}
            handleSnackbar={handleSnackbar}
            setModalState={setModalState}
            state={state}
            setState={setState}
          />
        );
      default:
        return;
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarState((prevState) => ({ ...prevState, open: false }));
  };

  const setFacility = useCallback(() => {
    checkFacility({ ...props, facilityId }).then((results) => {
      let fixedNodeHashMap = {};
      if (results.success) {
        const { facility = {}, fixedNodes = [] } = results;
        const { images = [], propertiesMap = {} } = facility;
        const {
          facilityImage = {
            zeroPoint: { x: 0, y: 0, disablePlunger: false },
            grid: { x: 0, y: 0 },
          },
        } = propertiesMap;
        const zoneIds = [];

        // Extract zoneID's out of the fixed nodes so we can
        // find the associated radios
        fixedNodes.forEach((node = {}) => {
          // If the zoneId is NOT in the zoneIDs array, push it on up
          if (!zoneIds.includes(node.zoneId)) {
            zoneIds.push(node.zoneId);
          }

          // We also set up the fixedNodeHashMap for the searchRadios function
          fixedNodeHashMap[node.name] = { ...node, children: {} };
        });

        searchRadios(props, zoneIds).then((res) => {
          const { radios = [] } = res;
          const nodesArray = [];

          // Divvy's up the radios to the appropiate fixedNode
          radios.forEach((radio) => {
            if (fixedNodeHashMap[radio.node]) {
              fixedNodeHashMap[radio.node].children[radio.radioId] = radio;
            }
          });

          // Then turn it into an array for the table
          Object.keys(fixedNodeHashMap)
            .sort()
            .forEach((item) => {
              nodesArray.push({
                node: item,
                children: fixedNodeHashMap[item].children,
                ...fixedNodeHashMap[item],
              });
            });

          setState((prevState) => ({
            ...prevState,
            ...facilityImage,
            facilityImage: images[0] ? images[0].url : null,
            facilityImageId: images[0] ? images[0].imageId : null,
            fixedNodes: nodesArray,
            loading: false,
          }));
        });
      } else {
        setState((prevState) => ({ ...prevState, loading: false }));
      }
    });
  }, [facilityId, props]);

  useEffect(() => {
    setFacility();
    getZoneHashMap(props).then((res) =>
      setState((prevState) => ({ ...prevState, zoneHashMap: res }))
    );
  }, [props, setFacility]);

  function handleSnackbar(content, error) {
    setSnackbarState((prevState) => ({
      ...prevState,
      content,
      error,
      open: true,
    }));
    setTimeout(() => {
      setSnackbarState((prevState) => ({ ...prevState, open: false }));
    }, 3000);
  }

  return (
    <Grid container>
      {/* SnackBar */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbarState.error ? "error" : "success"}
        >
          {snackbarState.content}
        </MuiAlert>
      </Snackbar>

      {/* Modal */}
      <ModalDialog
        handleClose={() => {
          setModalState({ modalShow: false, title: "", content: "" });
        }}
        open={modalState.modalShow}
        title={modalState.title}
        content={switchModal(modalState.title)}
      />
      {redirect.isRedirect ? (
        <Redirect
          to={{
            pathname: "/facilities/",
            state: { ...redirect.state },
          }}
        />
      ) : null}

      {/* Header / Back to Facilities / Zero Point button */}
      <Grid className={classes.titleContainer} item xs={12}>
        {/* Header */}
        <Grid item xs={6}>
          <h3>{name}</h3>
        </Grid>

        {/* Back and Set Zero Point Buttons*/}
        <Grid className={classes.buttonContainer} item xs={6}>
            <HoverIconButton
              handleClick={() => {
                setRedirect({
                  isRedirect: true,
                  pathName: "/facilities",
                  state: {},
                });
              }}
              icon="fas fa-arrow-left"
              iconDirection="left"
              text="Back to Facilities"
            />

          {/* Reset Image Button */}
          <MaterialUiButton
            className={classes.button}
            disabled={!state.facilityImage}
            label="Reset Facility Image"
            size="small"
            onClick={() => {
              setModalState((prevState) => ({
                ...prevState,
                title: "Reset Facility Image",
                modalShow: true,
              }));
            }}
          />

          {/* Zero Point Button */}
          <MaterialUiButton
            className={classes.button}
            // Disable button if no facilityId is present
            disabled={!facilityId}
            label={
              zeroPoint.disablePlunger ? "Reset Zero Point" : "Set Zero Point"
            }
            size="small"
            onClick={() => {
              if (!zeroPoint.disablePlunger) {
                setState((prevState) => ({
                  ...prevState,
                  zeroPoint: {
                    ...prevState.zeroPoint,
                    disablePlunger: true,
                  },
                }));
                updateFacility(
                  { ...props, facilityId, state },
                  {
                    zeroPoint: {
                      ...zeroPoint,
                      disablePlunger: true,
                    },
                  }
                );

                // If both plungers are set, have the user define their grid
                if (farPoint.disablePlunger && !zeroPoint.disablePlunger) {
                  setModalState({
                    modalShow: true,
                    title: "Define Grid",
                  });
                }
              } else {
                setState((prevState) => ({
                  ...prevState,
                  zeroPoint: {
                    ...prevState.zeroPoint,
                    disablePlunger: false,
                  },
                }));
              }
            }}
          />

          {/* Far Point Button */}
          <MaterialUiButton
            className={classes.button}
            // Disable button if no facilityId is present
            disabled={!facilityId}
            label={
              farPoint.disablePlunger ? "Reset Far Point" : "Set Far Point"
            }
            size="small"
            onClick={() => {
              if (!farPoint.disablePlunger) {
                setState((prevState) => ({
                  ...prevState,
                  farPoint: { ...prevState.farPoint, disablePlunger: true },
                }));
                updateFacility(
                  { ...props, facilityId, state },
                  { farPoint: { ...farPoint, disablePlunger: true } }
                );

                // If both plungers are set, have the user define their grid
                if (!farPoint.disablePlunger && zeroPoint.disablePlunger) {
                  setModalState({
                    modalShow: true,
                    title: "Define Grid",
                  });
                }
              } else {
                setState((prevState) => ({
                  ...prevState,
                  farPoint: { ...prevState.farPoint, disablePlunger: false },
                }));
              }
            }}
          />
        </Grid>
      </Grid>

      {/* Facility Overview */}
      <Card className={classes.card}>
        {!facilityId ? (
          <NoFacilityPresent />
        ) : state.loading ? (
          <Grid className={classes.loading} item xs={12}>
            <CircularProgress />
          </Grid>
        ) : state.facilityImage ? (
          <FacilityImage
            facilityImage={state.facilityImage}
            setModalState={setModalState}
            state={state}
            setState={setState}
          />
        ) : (
          <FacilityImageUpload
            {...props}
            facilityId={facilityId}
            handleSnackbar={handleSnackbar}
            setState={setState}
          />
        )}
      </Card>
    </Grid>
  );
}
