import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import MaterialConfirmationModal from "../Modals/MaterialConfirmationModal";

import { hideAlert } from "../../globalStore/slices/alert/alertSlice";

import SideNav from "./SideNav/SideNav";
import FreshDeskWidget from "../../app/FreshDesk/FreshDeskWidget";
import { useFreshDeskAuth } from "../../app/FreshDesk/useFreshDeskAuth";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#E6E7E8",
      display: "flex",
      fontSize: "1rem",
      height: "100vh",
      lineHeight: "1.5",
      overflow: "auto",
    },
    toolbar: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      minHeight: "100vh",
      flexGrow: 1,
      padding: theme.spacing(3, 5, 3, 3),
    },
    version: {
      bottom:'0',
      color: 'rgba(109,110,112,.3)',
      display:'flex',
      fontSize:'smaller',
      justifyContent:'center',
      position:'absolute',
      width:'80vw',
    }
  })
);

export default function AuthLayout(props) {
  const sideNavWidth = 90;
  const appBarHeight = 60;
  const classes = useStyles();

  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const token = useSelector((state) => state.user.token);

  useFreshDeskAuth(token);

  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <FreshDeskWidget />
        <SideNav sideNavWidth={sideNavWidth} appBarHeight={appBarHeight} />
        <main className={classes.content}>
          <MaterialConfirmationModal
            closeModal={() => {
              dispatch(hideAlert({}));
            }}
            content={alert.text}
            modalOpen={alert.show}
            severity={alert.type || "error"}
          />

          {props.children ? props.children : null}
          {/* Bottom padding for help desk widget */}
          <div style={{ paddingBottom: "75px" }}></div>
          
          {/* Version */}
          <div className={classes.version}>VERSION 2.0.0</div>
        </main>
      </div>
    </>
  );
}
